import React, { Component } from "react";
import "../App.css";
import { withRouter } from "react-router";
// import Carousel from 'react-bootstrap/Carousel'
import { Carousel, Container, Row, Col } from "react-bootstrap";
import Auth from "../Auth";
import axios from "../utils/axios";
import Alert from "../utils/Alert";

import { GoogleLogin } from "react-google-login";

var THIS;
class Registration extends Component {
  constructor(props) {
    super(props);
    this.changeGander = this.changeGander.bind(this);
    this.genderAccept = this.genderAccept.bind(this);
    this.changeGanderLooking = this.changeGanderLooking.bind(this);
    this.lookingGenderAccept = this.lookingGenderAccept.bind(this);
    this.registration = this.registration.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.state = {
      options: {
        male: false,
        female: false,
      },
      gender: "",
      genderStatus: false,
      lookingOptions: {
        men: false,
        women: false,
        both: false,
      },
      lookingGender: "",
      lookingGenderStatus: false,
      username: "",
      mobile: "",
      password: "",
      state: "",
      city: "",
      age: "",
    };
    THIS = this;
  }

  changeGander(opt) {
    var opts = this.state.options;

    switch (opt) {
      case "male":
        opts.male = true;
        opts.female = false;
        break;

      case "female":
        opts.female = true;
        opts.male = false;
        break;
    }

    this.setState({
      options: opts,
    });
  }

  genderAccept() {
    var gender = this.state.options;
    if (gender.male == true) {
      gender = "male";
    } else if (gender.female == true) {
      gender = "female";
    }

    this.setState({
      gender: gender,
      genderStatus: true,
    });
  }

  responseGoogle = (response) => {
    var _THIS = this;

    Auth.googleLoginCall(
      response.profileObj.googleId,
      response.profileObj.email,
      response.accessToken,
      (res) => {
        if (res) {
          _THIS.props.history.replace("/home");
          window.location.reload();
        }
      }
    );
  };

  changeGanderLooking(lookopt) {
    var lookopts = this.state.lookingOptions;

    switch (lookopt) {
      case "men":
        lookopts.men = true;
        lookopts.women = false;
        lookopts.both = false;
        break;

      case "women":
        lookopts.women = true;
        lookopts.men = false;
        lookopts.both = false;
        break;

      case "both":
        lookopts.both = true;
        lookopts.women = false;
        lookopts.men = false;
        break;
    }

    this.setState({
      lookingOptions: lookopts,
      // lookingGenderStatus:true,
    });

    // console.log(this.state.lookingOptions)
  }

  lookingGenderAccept() {
    var lookgen = this.state.lookingOptions;

    // console.log('lookopts', lookopts);
    if (lookgen.men == true) {
      lookgen = "men";
    } else if (lookgen.women == true) {
      lookgen = "women";
    } else if (lookgen.both == true) {
      lookgen = "both";
    }

    this.setState({
      lookingGender: lookgen,
      lookingGenderStatus: true,
    });
  }

  handleInput(event) {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  registration() {
    var genderUser = this.state.gender;
    var lookingGenderUser = this.state.lookingGender;
    var username = this.state.username;
    var mobile = this.state.mobile;
    var password = this.state.password;
    var state = this.state.state;
    var city = this.state.city;
    var age = this.state.age;

    if (
      username == "" &&
      mobile == "" &&
      password == "" &&
      state == "" &&
      city == "" &&
      age == ""
    ) {
      Alert.showMessage("Amoure", "All fields are required", "error", "OK");
    } else if (username == "") {
      Alert.showMessage("Amoure", "Please enter email", "error", "OK");
    } else if (mobile == "") {
      Alert.showMessage("Amoure", "Please enter mobil number", "error", "OK");
    } else if (password == "") {
      Alert.showMessage("Amoure", "Please enter Password", "error", "OK");
    } else if (state == "") {
      Alert.showMessage("Amoure", "Please enter state", "error", "OK");
    } else if (city == "") {
      Alert.showMessage("Amoure", "Please enter city", "error", "OK");
    } else if (age == "") {
      Alert.showMessage("Amoure", "Please enter age", "error", "OK");
    } else {
      var _THIS = this;

      Auth.registration(
        username,
        mobile,
        password,
        city,
        state,
        age,
        genderUser,
        lookingGenderUser,
        (res) => {
          // console.log('res', res);
          if (res) {
            _THIS.props.history.replace("/home");
            window.location.reload();
          }
        }
      );
    }
  }

  render() {
    return (
      <>
        <div className="banner new-block">
          <div
            id="slider"
            className="slider1 slider-stl owl-carousel owl-theme"
          >
            <div className="item">
              <div className="slider-block new-block">
                <div className="fixed-bg img_style"></div>
                <div className="overlay"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7 col-md-7">
                      <div className="slider-txt">
                        <div className="new-block cover-block">
                          <h1
                            className=""
                            data-animation-in="fadeInUp"
                            data-animation-out="animate-out fadeOutRight"
                            style={{ fontSize: "52px" }}
                          >
                            <span style={{ color: "#fdb813" }}>100%</span>{" "}
                            Entertainment
                          </h1>
                        </div>
                        <div className="new-block cover-block">
                          <p
                            data-animation-in="fadeInDown"
                            data-animation-out="animate-out fadeOutRight"
                          >
                            Connecting singles across the world to their ideal
                            partner
                          </p>
                        </div>
                        <div className="new-block cover-block">
                          <h1
                            className=""
                            data-animation-in="fadeInLeft"
                            data-animation-out="animate-out fadeOutRight"
                          >
                            <span style={{ color: "#fdb813" }}>WELCOME</span>{" "}
                            Amoure
                          </h1>
                          {/*<div className="btn-cover">
                                                    <a href="#" className="c-btn btn1" data-animation-in="fadeInLeft" data-animation-out="animate-out fadeOutRight">View More</a>
                                                  </div>
                                                  <div className="btn-cover">
                                                    <a href="#" className="c-btn btn2" data-animation-in="fadeInLeft" data-animation-out="animate-out fadeOutRight">Register</a>
                                                  </div>*/}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="slider-block new-block">
                <div className="fixed-bg img2_style"></div>
                <div className="overlay"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7 col-md-7">
                      <div className="slider-txt">
                        <div className="new-block cover-block">
                          <h1
                            className=""
                            data-animation-in="fadeInUp"
                            data-animation-out="animate-out fadeOutRight"
                            style={{ fontSize: "52px" }}
                          >
                            <span style={{ color: "#fdb813" }}>100%</span>{" "}
                            Entertainment
                          </h1>
                        </div>
                        <div className="new-block cover-block">
                          <p
                            data-animation-in="fadeInDown"
                            data-animation-out="animate-out fadeOutRight"
                          >
                            Connecting singles across the world to their ideal
                            partner
                          </p>
                        </div>
                        <div className="new-block cover-block">
                          <h1
                            className=""
                            data-animation-in="fadeInLeft"
                            data-animation-out="animate-out fadeOutRight"
                          >
                            <span style={{ color: "#fdb813" }}>WELCOME</span>{" "}
                            Amoure
                          </h1>
                          {/* <div className="btn-cover">
                                                   <a href="#" className="c-btn btn1" data-animation-in="fadeInLeft" data-animation-out="animate-out fadeOutRight">View More</a>
                                                 </div>
                                                 <div className="btn-cover">
                                                   <a href="#" className="c-btn btn2" data-animation-in="fadeInLeft" data-animation-out="animate-out fadeOutRight">Register</a>
                                                 </div>*/}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="slider-block new-block">
                <div className="fixed-bg img3_style"></div>
                <div className="overlay"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7 col-md-7">
                      <div className="slider-txt">
                        <div className="new-block cover-block">
                          <h1
                            className=""
                            data-animation-in="fadeInUp"
                            data-animation-out="animate-out fadeOutRight"
                            style={{ fontSize: "52px" }}
                          >
                            <span style={{ color: "#fdb813" }}>100%</span>{" "}
                            Entertainment
                          </h1>
                        </div>
                        <div className="new-block cover-block">
                          <p
                            data-animation-in="fadeInDown"
                            data-animation-out="animate-out fadeOutRight"
                          >
                            Connecting singles across the world to their ideal
                            partner
                          </p>
                        </div>
                        <div className="new-block cover-block">
                          <h1
                            className=""
                            data-animation-in="fadeInLeft"
                            data-animation-out="animate-out fadeOutRight"
                          >
                            <span style={{ color: "#fdb813" }}>WELCOME</span>{" "}
                            Amoure
                          </h1>
                          {/*<div className="btn-cover">
                                                    <a href="#" className="c-btn btn1" data-animation-in="fadeInLeft" data-animation-out="animate-out fadeOutRight">View More</a>
                                                  </div>
                                                  <div className="btn-cover">
                                                    <a href="#" className="c-btn btn2" data-animation-in="fadeInLeft" data-animation-out="animate-out fadeOutRight">Register</a>
                                                  </div>*/}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="overlay-design">
               <div className="container">
                 <div className="row">
                   <div className="col-lg-7 col-md-7">
                     
                   </div>
                   <div className="col-lg-5 col-md-5">
                     <div className="introducing-wrapper">
                       <div className="introducing-block ">
                         <div className="inner-wrapper">
                           <h2>Introducing Amoure</h2>
                           <p>Connecting singles across the world to their ideal partner</p>
                           <form>
                             <label>I am a :</label>
                             <div className="form-group">
                               <select>
                                 <option value="" selected>Choose Your Preferences</option>
                                 <option value="">Male</option>
                                 <option value="">Female</option>
                                 <option value="">Other</option>
                               </select>
                             </div>
                             <label>Between Age :</label>
                             <div className="age-block">
                               <div className="form-group">
                                 <select>
                                   <option value="" selected>18</option>
                                   <option value="">19</option>
                                   <option value="">20</option>
                                   <option value="">21</option>
                                   <option value="">22</option>
                                 </select>
                               </div>
                               <div className="form-group">
                                 <select>
                                   <option value="" selected>30</option>
                                   <option value="">31</option>
                                   <option value="">32</option>
                                   <option value="">33</option>
                                   <option value="">34</option>
                                 </select>
                               </div>
                             </div>
                             <div className="form-group">
                               <a href="#" className="c-btn bubbly-button btn1">Take a Chance</a>
                             </div>
                             <div className="form-group">
                               <a href="#" className="c-btn btn-fb"><i className="flaticon-social-facebook-circular-button"></i> Sign In</a>
                               <a href="#" className="c-btn btn-gplus"><img src="images/gpluse.png" alt=""/> <span>Sign In</span></a>
                             </div>
                           </form>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
           </div>*/}

          <div className="overlay-design">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7"></div>
                <div className="col-lg-5 col-md-5">
                  <div className="introducing-wrapper">
                    <div className="introducing-block ">
                      <div className="inner-wrapper">
                        <GoogleLogin
                          clientId="204239561952-iuccvhcpspf762bpj8r9bv3r1bfmb593.apps.googleusercontent.com"
                          render={(renderProps) => (
                            <button
                              onClick={renderProps.onClick}
                              disabled={renderProps.disabled}
                            >
                              This is my custom Google button
                            </button>
                          )}
                          buttonText="Login"
                          onSuccess={this.responseGoogle}
                          onFailure={this.responseGoogle}
                          cookiePolicy={"single_host_origin"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="why-join new-block pdtb-100">
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title2">
                  <h2 className="fz35">: Process at Amoure :</h2>
                  <div className="clearfix"></div>
                  <p className="fz20">
                    Aliquam a neque tortor. Donec iaculis auctor turpis.
                    Eporttitor
                    <br /> mattis ullamcorper urna. Cras quis elementum
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="block-syl1">
                  <i className="flaticon-protection-shield-with-a-check-mark"></i>
                  <p>Protection</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="block-syl1">
                  <i className="flaticon-circle"></i>
                  <p>Verification</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="block-syl1">
                  <i className="flaticon-love"></i>
                  <p>Attention</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="block-syl1">
                  <i className="flaticon-multimedia"></i>
                  <p>Communication</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="search-by-map new-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="map-txt-block">
                  <div className="inner-content-wrapper">
                    <div className="title1">
                      <h2 className="fz35">Search By Map :</h2>
                      <p className="fz20">
                        Connecting singles across the world to their ideal
                        partner
                      </p>
                    </div>
                    <p className="fz20">
                      Aliquam a neque tortor. Donec iaculis auctor turpis.
                      Eporttitor tristique mattis. Vestibulum pretium eleifend
                      risus, scelerisque neque rhoncus in.
                    </p>
                    <p className="fz20">
                      Curabitur volutpat rhoncus diam. Sed ac hendrerit Aliquam
                      vestibulum augue in tortor ultrices blandit. ClassName
                      aptent taciti sociosqu ad litora torquent per conubia
                      nostra
                    </p>
                    <a href="#" className="c-btn btn1">
                      See Full Map
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd0">
                <div className="map-section map-area">
                  <div id="map1"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="why-join new-block pdtb-100">
          <div className="overlay"></div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="title2">
                  <h2 className="fz35">: Why Join Amoure :</h2>
                  <div className="clearfix"></div>
                  <p className="fz20">
                    Aliquam a neque tortor. Donec iaculis auctor turpis.
                    Eporttitor
                    <br /> mattis ullamcorper urna. Cras quis elementum
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="block-stl1">
                  <p className="fz20">
                    <span className="clr1">There's tons of singles.</span> We're
                    one of the biggest dating sites on earth, and it's all
                    totally free.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="block-stl1">
                  <p className="fz20">
                    <span className="clr1">No fakes.</span> We strictly monitor
                    who uses our site, and you can easily block people who you
                    don't want to talk to
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="block-stl1">
                  <p className="fz20">
                    <span className="clr1">It's quick</span> - Most people that
                    use this site find a match within minutes.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="block-stl1">
                  <p className="fz20">
                    <span className="clr1">It's easy </span>- Amoure is a piece
                    of cake to use. Within minutes you'll be chatting with
                    potential dates in your area.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="online-member new-block pdt-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title2">
                  <h2 className="fz35">Members Online Now</h2>
                  <div className="clearfix"></div>
                  <p className="fz20">
                    Aliquam a neque tortor. Donec iaculis auctor turpis.
                    Eporttitor
                    <br /> mattis ullamcorper urna. Cras quis elementum
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="filter-nav">
                  <button className="btn btn1 filter-1" data-filter="all">
                    All
                  </button>
                  <button
                    className="btn btn1 filter-1"
                    data-filter=".category-1"
                  >
                    New Girl Member
                  </button>
                  <button
                    className="btn btn1 filter-1 active"
                    data-filter=".category-2"
                  >
                    New Boy Member
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="filter-area new-block">
            <div className="container">
              <div className="row" id="MixItUp1">
                <div className="mix category-1 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img
                        src="images/img1.jpg"
                        alt=""
                        className="img-responsive"
                      />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="mix category-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img
                        src="images/img2.jpg"
                        alt=""
                        className="img-responsive"
                      />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / M / Philippines </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="mix category-1 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img
                        src="images/img3.jpg"
                        alt=""
                        className="img-responsive"
                      />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="mix category-1 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img
                        src="images/img4.jpg"
                        alt=""
                        className="img-responsive"
                      />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="mix category-1 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img
                        src="images/img5.jpg"
                        alt=""
                        className="img-responsive"
                      />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="mix category-1 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img
                        src="images/img6.jpg"
                        alt=""
                        className="img-responsive"
                      />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="mix category-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img
                        src="images/img7.jpg"
                        alt=""
                        className="img-responsive"
                      />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / M / Philippines </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="mix category-1 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img
                        src="images/img8.jpg"
                        alt=""
                        className="img-responsive"
                      />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="easy-connect new-block pdt-100">
          <div className="fixed-bg parallax img4_style"></div>
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-7">
                <div className="connect-block">
                  <h3>
                    easy connect <span>to everyone</span>
                  </h3>
                  <p className="fz35">
                    Rulla a tempus turpis. Duis lacinia a est eu pulvinar.
                    Nullam justo velit, vulputate et massa eu
                  </p>
                  <a href="#" className="c-btn-ico btn1">
                    <i className="flaticon-playstore"></i>Get it on Google Play
                  </a>
                  <a href="#" className="c-btn-ico btn2">
                    <i className="flaticon-apple"></i>Downlaod on the App Store
                  </a>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-5 hidden-xs">
                <div className="img-holder">
                  <img src="images/mob.png" alt="" className="img-responsive" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="member-feedback new-block pdtb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title2">
                  <h2 className="clr1 fz35">Amoure Member Feedback</h2>
                  <div className="clearfix"></div>
                  <p className="fz20">
                    Aliquam a neque tortor. Donec iaculis auctor turpis.
                    Eporttitor
                    <br /> mattis ullamcorper urna. Cras quis elementum
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testimonial owl-carousel owl-theme">
                  <div className="item">
                    <div className="new-block">
                      <div className="block-stl3">
                        <i className="flaticon-left-quote"></i>
                        <p>
                          Fusce vitae nisi sempe ultrices sapien nec, pharetra
                          odio. Donec efficitur hendrerit
                        </p>
                        <i className="flaticon-right-quotation-sign"></i>
                        <div className="arrow-down"></div>
                      </div>
                      <div className="sm-block">
                        <div className="img-holder">
                          <img src="images/tm1.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="new-block">
                      <div className="block-stl3">
                        <i className="flaticon-left-quote"></i>
                        <p>
                          Fusce vitae nisi sempe ultrices sapien nec, pharetra
                          odio. Donec efficitur hendrerit
                        </p>
                        <i className="flaticon-right-quotation-sign"></i>
                        <div className="arrow-down"></div>
                      </div>
                      <div className="sm-block">
                        <div className="img-holder">
                          <img src="images/tm2.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="new-block">
                      <div className="block-stl3">
                        <i className="flaticon-left-quote"></i>
                        <p>
                          Fusce vitae nisi sempe ultrices sapien nec, pharetra
                          odio. Donec efficitur hendrerit
                        </p>
                        <i className="flaticon-right-quotation-sign"></i>
                        <div className="arrow-down"></div>
                      </div>
                      <div className="sm-block">
                        <div className="img-holder">
                          <img src="images/tm3.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="new-block">
                      <div className="block-stl3">
                        <i className="flaticon-left-quote"></i>
                        <p>
                          Fusce vitae nisi sempe ultrices sapien nec, pharetra
                          odio. Donec efficitur hendrerit
                        </p>
                        <i className="flaticon-right-quotation-sign"></i>
                        <div className="arrow-down"></div>
                      </div>
                      <div className="sm-block">
                        <div className="img-holder">
                          <img src="images/tm2.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="client-area new-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="block-stl4 bg1">
                  <i className="flaticon-happiness"></i>
                  <p className="fz20">Happy Clients</p>
                  <div className="counter-block">
                    <span className="counter">3224</span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="block-stl4 bg2">
                  <i className="flaticon-people"></i>
                  <p className="fz20">All Members</p>
                  <div className="counter-block">
                    <span className="counter">474</span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="block-stl4 bg3">
                  <i className="flaticon-users"></i>
                  <p className="fz20">Online User</p>
                  <div className="counter-block">
                    <span className="counter">241</span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="block-stl4 bg4">
                  <i className="flaticon-circle"></i>
                  <p className="fz20">Happy Dating</p>
                  <div className="counter-block">
                    <span className="counter">545</span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="block-stl4 bg5">
                  <i className="flaticon-trophy"></i>
                  <p className="fz20">Happy people</p>
                  <div className="counter-block">
                    <span className="counter">314</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <section className="Amoure-suggest new-block">
            <div className="container">
               <div className="row reg_container_alignment">
                 <div className="container register">
                   <div className="row">
                       <div className="col-md-3 register-left">
                         <div className="sideContentAlignment">
                           <h3>Welcome Amoure</h3>
                           <p style={{color: '#fff'}}>100% Entertainment</p>
                         </div>
                       </div>
                       <div className="col-md-9 register-right">
                         
   
                        
                       </div>
                   </div>
                 </div>
               </div>
            </div>
       </section>*/}
      </>
    );
  }
}

export default withRouter(Registration);
