import React, { Component } from 'react';
import '../App.css';

export default class AdvanceSearch extends Component {
  render(){
    return (
      <>
        {/*<div>*/}

          <div className="search-form new-block pdtb-50">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner-wrapper">
                    <form>
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <label>I am a :</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Men</option>
                                <option value="">Girl</option>
                                <option value="">other</option>
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <label>Looking For</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Choose Your Preferences</option>
                                <option value="">Men</option>
                                <option value="">Girl</option>
                                <option value="">other</option>
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <label>Location</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Choose Your Preferences</option>
                                <option value="">Usa</option>
                                <option value="">Uk</option>
                                <option value="">India</option>
                                <option value="">china</option>
                              </select>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                          <label>Age : <span id="result_1"> 18 - 25</span></label>
                            <div className="form-group">
                              <input id="range_1" />
                                        </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <label>Height : <span id="result_2"> 145cm - 210cm</span></label>
                            <div className="form-group">
                              <input id="range_2" />
                                        </div>
                          </div>
                        <div className="col-lg-4 col-md-4">
                          <label>Weight : <span id="result_3"> 40kg - 80kg</span></label>
                            <div className="form-group">
                              <input id="range_3" />
                                        </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                          <label>Education :</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Graduate</option>
                                <option value="">Post Graduate</option>
                                <option value="">Studying</option>
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <label>Social Status :</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Choose Your Preferences</option>
                                <option value="">Facebook</option>
                                <option value="">twitter</option>
                                <option value="">whatsapp</option>
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <label>Working in :</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>All</option>
                                <option value="">It sector</option>
                                <option value="">business</option>
                                <option value="">Multimedia</option>
                              </select>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                          <label>Marital Status :</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Single</option>
                                <option value="">married</option>
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <label>Smoking :</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Yes</option>
                                <option value="">No</option>
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <label>Drinking :</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Yes</option>
                                <option value="">No</option>
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="text-center">
                              <a href="#" className="c-btn btn1">Search</a>
                            </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="search-result-area new-block pdtb-50">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="title2">
                    <h2 className="fz35">Search Result : 247</h2>
                    <div className="clearfix"></div>
                    <p className="fz20">Aliquam a neque tortor. Donec iaculis auctor turpis. Eporttitor<br/> mattis ullamcorper urna. Cras quis elementum</p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img12.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img13.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img14.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img15.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img16.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img17.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img18.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img19.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img20.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img21.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img22.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img23.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img24.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img25.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img26.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img27.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img28.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img29.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img30.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img31.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img32.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img33.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img34.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img35.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="text-center">
                    <a href="#" className="c-btn btn1"> View More</a>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="lamour-suggest new-block pdtb-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="title2">
                    <h2 className="fz35">Search Result : 247</h2>
                    <div className="clearfix"></div>
                    <p className="fz20">Aliquam a neque tortor. Donec iaculis auctor turpis. Eporttitor<br/> mattis ullamcorper urna. Cras quis elementum</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img1.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / F / Philippines  </p>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img2.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / M / Philippines  </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img3.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / F / Philippines  </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img4.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / F / Philippines  </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img5.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / F / Philippines  </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img6.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / F / Philippines  </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img7.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / M / Philippines  </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img8.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / F / Philippines  </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          
          
        {/*</div>*/}
      </>
    );
  }
}
