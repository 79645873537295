import React from "react";
import { GENDER_LIST, COUNTRY_LIST } from "../../utils/constants";
import useCompleteProfile from "./useCompleteProfile";
import Input from "../../Components/Input";
import capitalize from "../../helpers/capitalize";
import "./completeProfile.css";
const CompleteProfileNew = () => {

  const {
    form,
    logout,
    countries,
    handleChange,
    handleGenderChange,
    saveCompleteProfile,
    errorStatus,
    errorMessage,
  } = useCompleteProfile();

  window.showHeader = false;

  return (
    <section className="lamour-suggest new-block content_align">
      <div className="container">
        <h2 style={{ textAlign: "center" }}>Complete Profile</h2>
        <div class="form-group">
          <label for="gender">Choose Gender</label>
          <br />
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label
              class="btn btn-secondary active"
              onClick={() => handleGenderChange("gender", "M")}
            >
              <input
                type="radio"
                name="gender"
                id="male"
                value={form?.gender}
                autoComplete="off"
                checked={form?.gender === "M"}
              />
              Male
            </label>
            <label
              class="btn btn-secondary"
              onClick={() => handleGenderChange("gender", "F")}
            >
              <input
                type="radio"
                name="gender"
                value={form?.gender}
                id="female"
                autoComplete="off"
                checked={form?.gender === "F"}
              />
              Female
            </label>
          </div>
        </div>
        <div class="form-group">
          <label for="username">Username</label>
          <input
            type="text"
            class="form-control"
            id="username"
            name="username"
            onChange={(e) => handleChange(e)}
            placeholder="Enter username"
          />
        </div>
        <div class="form-group">
          <label for="age">Age</label>
          <input
            type="text"
            class="form-control"
            name="age"
            onChange={(e) => handleChange(e)}
            id="age"
            placeholder="Enter age"
          />
        </div>

        <div class="form-group">
          <label for="country_id">Country</label>
          <select
            class="form-control"
            name="country_id"
            id="country_id"
            onChange={(e) => handleChange(e)}
            value={form?.country_id}
          >
            <option>Select Country</option>
            {countries?.length &&
              countries?.map((country, idx) => (
                
                <option key={idx} value={country?.id}>
                  {country?.name}
                </option>
              ))}
          </select>
        </div>
        <br />
        <br />
        <div class="form-group">
          <label for="city">City</label>
          <input
            type="text"
            class="form-control"
            id="city"
            name="city"
            onChange={(e) => handleChange(e)}
            placeholder="Enter city"
          />
        </div>
        <br />
        <div className="col-md-12 pt-3 pl-3 pr-3">
          <button
            className="btn btn-danger btn-lg w-100 rounded-pill complete-profile-button-custome-class"
            onClick={() => saveCompleteProfile()}
          >
            Save
          </button>
        </div>
        <div className="col-md-12 pt-3 pl-3 pr-3">
          <button
            className="btn btn-default btn-lg w-100 rounded-pill"
            onClick={logout}
          >
            Logout
          </button>
        </div>
      </div>

      {(errorStatus && 1==2) && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="bg-danger w-50 px-4 py-4 rounded d-flex justify-content-center align-items-center">
            <span className="text-white d">{errorMessage}</span>
          </div>
        </div>
      )}
    </section>
  );
};

export default CompleteProfileNew;
