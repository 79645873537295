import React from "react";

const AmoureLoader = () => {
  return (
    <div className="custome-overlay style-2 d-flex justify-content-center align-items-center">
      <div className="loader-container">
        <i className="fa fa-spinner fa-spin fa-2x" style={{color: 'var(--color-primary)'}}></i>
      </div>
    </div>
  );
};

export default AmoureLoader;
