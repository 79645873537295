import React, { Component } from 'react';
import '../App.css';
import { withRouter } from "react-router";

import Auth from '../Auth';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnchor, faComments } from '@fortawesome/free-solid-svg-icons'
import { Link, Redirect } from 'react-router-dom';

class UserCard extends Component {

  openChat(id) {
    window.openGlobalChat(id);
  }

  render(){

    var id = btoa(this.props.user_data.id);

    return (
      <>
        <div className="col-lg-3 col-md-3 col-sm-6">
          <div className="userCardContainer" style={{backgroundImage: "url('"+this.props.user_data.profile_image+"')"}}>
          <div className='userCardClickArea'>
            <Link to={`/profile_details/${id}`}>
            
            </Link>
          </div>
          <div className='userCardGradientArea'>

          </div>
          {this.props.user_data.away_status== 'online' ?
              <div className="headerStyleOnline headerStyleUserStatus"></div>
              :
              <div className="headerStyleaway headerStyleUserStatus"></div>
            }
            
            {/* <img src={this.props.user_data.profile_image} alt="" className="fullImage" /> */}
            
            <div className="card_chat_icon" onClick={()=>this.openChat(this.props.user_data.id)}>
              <i className="fa fa-comment" />
            </div>

            <div className="card_profile_details">
              <span className='cardUsername'>{this.props.user_data.username.substring(0, 15)}</span>
              <span className='cardUsernameAge'>{this.props.user_data.age} / {this.props.user_data.state.substring(0, 13)}  </span>
            </div>
          </div>
        </div>
      </>
    );
  }

}


export default withRouter(UserCard);