import React, { useState, useCallback, useEffect, useMemo } from "react";

import Auth from "../../Auth";
import axios from "../../utils/axios";
import Alert from "../../utils/Alert";
import { useHistory } from "react-router-dom";
const useCompleteProfile = () => {
  const navigate = useHistory();
  const [form, setForm] = useState({
    username: "rana",
    city: "",
    age: "",
    gender: "male",
    country_id: "",
  });

  const [countries, setCountries] = useState([]);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    axios.api_post("fetch_countries", {}, (res) => {
      if (res?.data?.status) {
        if (res?.data?.countries?.length) {
          setCountries(res?.data?.countries);
        }
      }
    });
  }, []);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setForm({
        ...form,
        [name]: value,
      });
    },
    [form]
  );

  const handleGenderChange = useCallback(
    (name, value) => {
      setForm({
        ...form,
        [name]: value,
      });
    },
    [form]
  );

  const saveCompleteProfile = useCallback(() => {
    axios.api_post("complete_profile_v2", form, (res) => {
      if (!res?.data?.status) {
        // setErrorStatus(true);
        // setErrorMessage(res?.data?.data);
        Alert.showMessage("Complete Profile", res?.data?.data, "error", "OK");
      } else {
        window.showHeader = true;
        // navigate.push('/home');
        window.location.href="/home";
        // need modal state false
      }
    });
  }, [form]);

  useEffect(() => {
    if (errorStatus) {
      setTimeout(() => {
        setErrorStatus(false);
        setErrorMessage("");
      }, 5000);
    }
  }, [errorStatus]);

  let logout = () => {
    Auth.signout();
    window.location.reload();
    // navigate.push('/about');
  };

  return {
    form,
    handleChange,
    logout,
    countries,
    handleGenderChange,
    saveCompleteProfile,
    errorStatus,
    errorMessage,
  };
};

export default useCompleteProfile;
