import React, { Component } from "react";
import "../App.css";
import { withRouter } from "react-router";

import Auth from "../Auth";
import UserCard from "../Components/UserCard";
import axios from "../utils/axios";
import { Link as RouterLink, Redirect } from "react-router-dom";

class ProfileVisitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      by_me: [],
      for_me: [],
    };
    this.getVisitors();
  }

  getVisitors() {
    var activity = "visit";
    axios.api_post("get_activity/" + activity, {}, (res) => {
      if (res.data.status == true) {
        this.setState({
          by_me: res.data.by_me,
          for_me: res.data.for_me,
        });
      }
    });
  }

  render() {
    return (
      <>
        <section className="lamour-suggest new-block content_align">
          <div className="container">
            <div className="row">
              <div className="container mt-3">
                <ul className="nav nav-tabs custom_activity_tab">
                  <li className="nav-item active">
                    <a className="nav-link" data-toggle="tab" href="#visitors">
                      Visitors
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#visited">
                      Visited
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div id="visitors" className="container tab-pane active">
                    <br />
                    {this.state.for_me.map((visitedData, index) => {
                      return <UserCard user_data={visitedData} />;
                    })}
                  </div>
                  <div id="visited" className="container tab-pane">
                    <br />
                    {this.state.by_me.map((visitorsData, index) => {
                      return <UserCard user_data={visitorsData} />;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(ProfileVisitor);
