import React, { Component } from "react";
import "../App.css";
import { withRouter } from "react-router";
// import Carousel from 'react-bootstrap/Carousel'
import { Carousel, Container, Row, Col } from "react-bootstrap";
import Auth from "../Auth";
import axios from "../utils/axios";
import Alert from "../utils/Alert";

// import { GoogleLogin } from "react-google-login";
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

var THIS;
class AppLandingNew extends Component {

  constructor(props) {
    super(props);

    const searchParams = new URLSearchParams(props.location.search);
    const tokenParam = searchParams.get("token");

    THIS = this;

    if(!tokenParam) {
      window.location.href="/auth";
    } else {
        Auth.validateUserToken(tokenParam, (res) => {
            if (res.data.status) {
              localStorage.setItem("token", tokenParam);
              THIS.props.history.replace("/home");
              window.location.reload();
            } else {
              Alert.showMessage("Amoure", "Invalid Authentication", "error", "OK");
            }
        });
    }

    this.state = {
        authenticating: true
    };

    

    // if (Auth.isAuthenticated()) {
    //   THIS.props.history.replace("/home");
    //   window.location.reload();
    // }

  }

  callSocialLogin = (id, email, provider, token) => {
    var _THIS = this;
    
  };

  render() {
    return (
      <>
        <div className="container">
            <div className="vh-100 d-flex justify-content-center align-items-center">
                <i className="fa fa-spinner fa-spin fa-2x"></i>
                 <h3 style={{marginLeft: '10px'}}>Authenticating...</h3>
            </div>
        </div>
      </>
    );
  }
}

export default withRouter(AppLandingNew);
