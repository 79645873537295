import React, { Component } from 'react';
import '../../App.css';
import Auth from '../../Auth';

export default class Footer extends Component {
  render(){
    return (
    	<>
		<div>
		{Auth.isAuthenticated() &&
			<div className="copyright new-block">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="social-nav">
								<ul>
									<li className="tw"><a href="#"><i className="flaticon-twitter"></i></a></li>
									<li className="fb"><a href="#"><i className="flaticon-facebook-logo"></i></a></li>
									<li className="gp"><a href="#"><i className="flaticon-google-plus-logo"></i></a></li>
									<li className="rss"><a href="#"><i className="flaticon-rss-symbol"></i></a></li>
								</ul>
							</div>
							<p>2023 Copyright <a href="#"> Amoure </a> All Rights Reserved</p>
						</div>
					</div>
				</div>
				</div>
			}
			</div>
			{/* <span id="go_to_top" className="go-to-top"><i className="fa fa-long-arrow-up"></i></span> */}
		</>
    );
  }
}
