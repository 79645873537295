import React, { Component } from "react";
import "../App.css";
import { withRouter } from "react-router";
// import Carousel from 'react-bootstrap/Carousel'
import { Carousel, Container, Row, Col } from "react-bootstrap";
import Auth from "../Auth";
import axios from "../utils/axios";
import Viewer from "react-viewer";
import "./details.css";

var THIS;
class DetailsNew extends Component {
  constructor(props) {
    super(props);

    const { data_details } = props;

    this.state = {
      showModal: false,
      image_url: "",
      favourite: data_details.favourite,
      crush: data_details.crush,
    };

    THIS = this;
  }

  openChat() {
    window.openGlobalChat(this.props.data_details.id);
  }

  toggleFavourite() {
    axios.api_post(
      "send_activity",
      {
        to_user: this.props.data_details.id,
        type: "favourite",
        extra_data: "",
      },
      (rs) => {
        this.setState({
          favourite: !this.state.favourite,
        });
      }
    );
  }

  toggleCrush() {
    axios.api_post(
      "send_activity",
      {
        to_user: this.props.data_details.id,
        type: "crush",
        extra_data: "",
      },
      (rs) => {
        this.setState({
          crush: !this.state.crush,
        });
      }
    );
  }

  handleKeyPress = (event) => {
    console.log(event.keyCode);
    if (event.keyCode === 27) {
      console.log("Escape key pressed!");
    }
  };

  render() {
    var gender = "";
    console.log(this.props.data_details);
    // console.log('hello', this.props.data_details)
    if (this.props.data_details.gender == "M") {
      gender = "Male";
    } else if (this.props.data_details.gender == "F") {
      gender = "Female";
    }

    return (
      <>
        <Viewer
          visible={this.state.showModal}
          images={[{ src: this.state.image_url }]}
          drag={false}
          scalable={false}
          downloadable={false}
          noNavbar={true}
          noFooter={true}
          noToolbar={true}
          onMaskClick={() => {
            this.setState({
              showModal: false,
            });
          }}
          onClose={() => {
            this.setState({
              showModal: false,
            });
          }}
        />
        <section
          className="lamour-suggest new-block content_align profile-block"
          onKeyDown={(e) => this.handleKeyPress(e)}
          style={{ backgroundColor: "#f6f6f6" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12 col-xs-12 col-sm-12">
                <div className="col-lg-4 col-xl-4 col-md-4 col-xs-4 col-sm-4 text-center">
                  <div className="profile-left-section">
                    <div className="profile_image_container">
                      <img src={this.props.data_details.profile_image} />
                      {this.props.data_details.online == true ? (
                        <div className="cardImageStatusUser detailsHeaderStyleOnline"></div>
                      ) : (
                        <div className="cardImageStatusUser detailsHeaderStyleaway"></div>
                      )}
                    </div>
                    <span className="onlineTime">{this.props.data_details.online ? "Online" : "Last activity "+this.props.data_details.seen_time_ago}</span>
                    <button
                      className="btn btn-block btn-block"
                      onClick={() =>
                        this.props.userBlock(
                          !this?.props?.data_details?.this_user_blocked
                            ? "block"
                            : "unblock"
                        )
                      }
                    >
                      {!this?.props?.data_details?.this_user_blocked
                        ? "Block"
                        : "Unblock"}
                    </button>
                    <button
                      className="btn btn-block btn-report"
                      onClick={() => this.props.openReportModal()}
                    >
                      Report
                    </button>
                  </div>
                </div>
                <div className="col-lg-8 col-xl-8 col-md-8 col-xs-8 col-sm-8">
                  <div>
                    <span className="username">
                      {this.props.data_details.username},{" "}
                      {this.props.data_details.age}
                    </span>
                  </div>
                  <div>
                    <span className="location">
                      {this.props.data_details.state},{" "}
                      {this.props.data_details.city}
                    </span>
                  </div>
                  <p className="bio">{this.props.data_details.bio}</p>
                  <div id="gallery row clearfix">
                    {this.props.data_details.images &&
                      this.props.data_details.images.map((img, index) => {
                        return (
                          <div className="gallery-item-container">
                            <div className="gallery-item">
                              <img
                                src={img.url}
                                alt=""
                                onClick={() => {
                                  this.setState({
                                    showModal: true,
                                    image_url: `${img.url}`,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="col-lg-12 col-xl-12 col-sm-12 col-md-12 col-xs-12">
                    <div className="row">
                      <div className="row personalContentAlignment">
                        {this.props.data_details.profile_fields &&
                          typeof this.props.data_details.profile_fields !=
                            "undefined" &&
                          this.props.data_details.profile_fields.map(
                            (fitem) => {
                              if (fitem.general_details == 1) {
                                return (
                                  <>
                                    <div className="border-item"></div>
                                    <div className="info-block">
                                      <p className="profileContentStyleSpace">
                                        {fitem.field_name} :
                                      </p>
                                      <div className="tab-holder">
                                        {fitem.options.map((opt_item) => {
                                          return (
                                            <span className="contentStyleContentTabs">
                                              {opt_item.option_name}
                                            </span>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            }
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-12 col-sm-12 col-md-12 col-xs-12">
                    <div className="row">
                      <div className="row personalContentAlignment">
                        {this.props.data_details.profile_fields &&
                          typeof this.props.data_details.profile_fields !=
                            "undefined" &&
                          this.props.data_details.profile_fields.map(
                            (fitem) => {
                              if (fitem.general_details == 0) {
                                return (
                                  <>
                                    <div className="border-item"></div>
                                    <div className="info-block">
                                      <p className="profileContentStyleSpace">
                                        {fitem.field_name} :
                                      </p>
                                      <div className="tab-holder">
                                        {fitem.options.map((opt_item) => {
                                          return (
                                            <span className="contentStyleContentTabs">
                                              {opt_item.option_name}
                                            </span>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            }
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile_bottom_button_holder">
            <div className="profile_bottom_buttons_inner_container">
              <div
                className="profileInteractionButtonBottom"
                onClick={() => this.toggleFavourite()}
              >
                {this.state.favourite == true ? (
                  <i className="fa fa-star" style={{ color: "orange" }}></i>
                ) : (
                  <i className="fa fa-star-o" style={{ color: "orange" }}></i>
                )}
              </div>
              <div
                className="profileInteractionButtonBottom"
                onClick={() => this.toggleCrush()}
              >
                {this.state.crush == true ? (
                  <i className="fa fa-heart" style={{ color: "red" }}></i>
                ) : (
                  <i className="fa fa-heart-o" style={{ color: "red" }}></i>
                )}
              </div>
              <div
                className="profileInteractionButtonBottom profileInteractionButtonBottomChat"
                onClick={() => this.openChat()}
              >
                <i className="fa fa-comment"></i>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(DetailsNew);
