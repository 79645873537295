import React, { Component } from 'react';
import '../App.css';
import { withRouter } from "react-router";

import Auth from '../Auth';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnchor, faComments, faCheckCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

class Crush extends Component {
  constructor(props) {
    super(props);
    this.deleteImage = this.deleteImage.bind(this);
  }

  deleteImage(){
    this.props.customDelete();
  }

  setDefauleImage(){
    this.props.customSetDefaultImage();
  }

  render(){
    // console.log(this.props.user_image.is_default);
    return (
      <>
        <div className="col-lg-3 col-md-3 col-sm-6">
          <div className="block-stl2">
            <div className="img-holder">
              <img src={this.props.user_image.url} alt="" className="img-responsive"  onClick={(e)=>this.setDefauleImage()}/>
            </div>
            {/*<div>{this.props.showHide}</div>*/}
            
            <div className="row" style={{padding : '8px'}}>
              <div className="col-md-8 col-lg-8">

                  {this.props.showHide == this.props.user_image.id  ?
                  
                    
                      <FontAwesomeIcon style={{backgroundColor : '#f76264'}} icon={faCheckCircle} color="#fff"/>
                    : this.props.user_image.is_default ==1 &&
                    
                      <FontAwesomeIcon style={{backgroundColor : '#f76264'}} icon={faCheckCircle} color="#fff"/>
                  }
                  
              </div>
              <div className="col-md-4 col-lg-4" >
                <FontAwesomeIcon style={{backgroundColor : '#f76264'}} icon={faTrashAlt} color="#fff" onClick={(e) => this.deleteImage()} />
              </div>
            </div>
          </div>

        </div>
      </>
    );
  }

}


export default withRouter(Crush);