import React, { Component } from "react";
import "../App.css";
import { withRouter } from "react-router";
import Auth from "../Auth";
import Photos from "../Components/Photos";
import axios from "../utils/axios";
import Alert from "../utils/Alert";
// import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnchor, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import AmoureLoader from "../Components/AmoureLoader";
class MyPhotos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      featchUserPhoto: [],
      showHideStatus: "",
      is_default: "",
      isLoading: false,
    };

    this.fileInputRef = React.createRef();

    this.getUserImage();
  }

  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  getUserImage() {
    axios.api_post("get_user_images", {}, (res) => {
      if (res.data.status == true) {
        this.setState({
          featchUserPhoto: res.data.data,
        });
      }
    });
  }

  customDeleteImage(id) {
    var image_id = id;

    axios.api_post(
      "delete_image_from_profile",
      {
        image_id: image_id,
      },
      (res) => {
        // console.log('res', res);
        if (res.data.status == true) {
          Alert.showMessage(
            "Amoure",
            "Picture remove successfully",
            "success",
            "OK"
          );
          this.getUserImage();
        }
      }
    );
  }

  setDefaultImage(id) {
    var image_id = id;
    // console.log('id', id)
    // console.log('is_default', is_default)
    var status = 1;

    axios.api_post(
      "set_image_default",
      {
        image_id: image_id,
      },
      (res) => {

        if (res.data.status == true) {

          this.getUserImage();

          this.setState({
            showHideStatus: image_id
          });

        }

      }
    );
  }

  onFileChange = (event) => {
    this.uploadFile(event.target.files[0]);
  };

  openFileChooser = () => {
    document.getElementById("select-image").click();
  };

  async uploadFile(file) {

    this.setState({
      isLoading: true,
    });

    const formData = new FormData();
    formData.append("photo", file);

    axios({
      method: "post",
      url: axios.getBaseUrl() + "upload_user_photo",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        // "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.status) {
        Alert.showMessage(
          "Amoure",
          "Picture uploaded successfully",
          "success",
          "OK"
        );
        this.getUserImage();
        this.setState({
          isLoading: false,
        });
      } else {
        Alert.showMessage("Amoure", "Invalid photo", "error", "OK");
        this.setState({
          isLoading: false,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.isLoading && <AmoureLoader />}
        <section className="lamour-suggest new-block content_align">
          <input
            type="file"
            id="select-image"
            style={{ display: "none" }}
            onChange={this.onFileChange}
            ref={this.fileInputRef}
          />
          <div className="container">
            <div className="row">
              <div
                className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center align-items-center"
                onClick={(e) => this.openFileChooser()}
              >
                <div className="block-stl2">
                  <div className="img-holder img-holder-center">
                    {/* <i className='fa fa-plus'></i> */}
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      color="#000"
                      size="2x"
                    />
                  </div>
                </div>
              </div>
              {this.state.featchUserPhoto.map((img, key) => {
                return (
                  <Photos
                    user_image={img}
                    showHide={this.state.showHideStatus}
                    customDelete={(e) => this.customDeleteImage(img.id)}
                    customSetDefaultImage={(e) => this.setDefaultImage(img.id)}
                  />
                );
              })}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(MyPhotos);
